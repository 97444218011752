<template>
  <div class="noC-login">
    <div class="noC-login-item">
      <!-- <div class="noC-login-logo"></div> -->
      <div class="noC-login-name">零探数字</div>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="noC-login-content">
      <el-form-item prop="username">
        <div class="noC-item">
          <div class="noC-item-name">手机号码</div>
          <el-input
            v-model="form.username"
            type="text"
            @change="changeCode"
            @focus="blurSearchFor()"
            @blur="checkError()"
            :placeholder="placeholder"
          />
        </div>
      </el-form-item>
      <div class="noC-item">
        <div class="noC-item-name">密码</div>
        <el-input
          v-model="form.password"
          :type="isShowPassword ? 'text' : 'password'"
          placeholder="8-16位,必须包含数字、大小写字母"
        />
        <div
          class="iconfont  noC-icon-set"
          @click="passwordShow"
          :class="isShowPassword ? 'icon-eye' : 'icon-no_eye'"
        ></div>
      </div>
      <div class="noC-item">
        <div class="noC-item-name">验证码</div>
        <div class="noC-img-item">
          <el-input
            v-model="form.code"
            type="text"
            placeholder="输入右侧图中验证码"
            @keyup.native.enter="login"
          />
          <div class="noC-img" ref="code" v-if="src">
            <img :src="src" alt="" @click="changeCode" v-if="src" />
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="noC-item-login" @click="login">
        <div>登录</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { captcha } from "@/api";
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
export default {
  name: "loginContainer",
  data() {
    return {
      isShowPassword: false,
      form: {
        username: "",
        password: "",
        code: "",
      },
      placeholder: "请您输入手机号",
      rules: {
        username: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请填写正确手机号码",
            trigger: "blur",
          },
        ],
      },
      img: "",
      src: "",
    };
  },
  mounted() {},
  methods: {
    passwordShow() {
      this.isShowPassword = !this.isShowPassword;
    },
    checkError() {
      this.$refs.form.validateField("username", (valid) => {
        if (valid) {
          this.form.username = "";
          return;
        }
      });
    },
    blurSearchFor() {
      this.$refs.form.validateField("username", (valid) => {
        if (valid) {
          this.form.username = "";
          this.$refs.form.clearValidate();
          return;
        }
      });
      if (this.placeholder == "请您输入手机号") {
        this.placeholder = "";
      }
    },
    //登录
    login() {
      this.$store
        .dispatch("login", {
          password: this.form.password,
          username: this.form.username,
          code: this.form.code,
        })
        .then(({ success, msg }) => {
          if (!success) {
            this.$message.error(msg);
            this.changeCode();
          } else {
            this.$store.state.userInfo.username = this.form.username;
            this.$store.state.userInfo.password = this.form.password;
            this.$message.success("登录成功");
            this.$parent.$parent.isContent = false;

            //判断是否携带路径
            if (this.$route.query.linkHref) {
              // return
              location.href = this.$route.query.linkHref;
            } else {
              this.$router.push("/");
            }

            // Bus.$emit('getUserInfo',this.$store.state.userInfo);
          }
        });
    },
    changeCode() {
      this.isCode = true;
      captcha({
        username: this.form.username,
      }).then((result) => {
        let reg = /^1[345789]\d{9}$/;
        if (!reg.test(this.form.username)) {
          this.src = false;
        } else {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.src = e.target.result;
          };
          reader.readAsDataURL(result.data);
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.noC-icon-set {
  display: inline;
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
}
.noC-login {
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 12px;
  .noC-login-item {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .noC-login-logo {
      width: 88px;
      height: 22px;
      background-image: url("../../assets/logo.png");
      background-size: cover;
    }
    .noC-login-name {
      font-weight: 400;
      font-size: 18px;
      line-height: 41px;
      margin-left: 16px;
    }
  }
  .noC-login-content {
    margin-top: 31px;
  }
  .noC-item {
    width: 396px;
    margin-bottom: 25px;
    position: relative;

    .noC-item-name {
      margin-bottom: 5px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
    }
    i {
      width: 5px;
      height: 5px;
    }
    .noC-img-item {
      overflow: hidden;
      display: flex;
      height: 50px;
      width: 396px;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 8px;
      .noC-img {
        flex-shrink: 0;
        width: 80px;
        height: 40px;
        margin-right: 20px;
        margin-top: 5px;
        background-color: #f7e8e3;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .noC-item-login {
    margin-top: 131px;
    width: 396px;
    height: 50px;
    cursor: pointer;
    background: #357aff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 10px;
    font-size: 22px;
    line-height: 21px;
    color: #ffffff;
  }
  // div {
  //   margin-bottom: 5px;
  // }
}
</style>
