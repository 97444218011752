<template>
  <div class="user-info">
    <div class="realName">
      <el-input v-model="userInfoDate.realName" type="text" />
    </div>
    <div class="nickname">
      <el-input v-model="userInfoDate.nickname" type="text" />
    </div>
    <div class="sex">
      <el-select v-model="userInfoDate.sex" placeholder="请选择性别">
        <el-option
          v-for="item in optionSex"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="mobilePhone">
      <el-input v-model="userInfoDate.mobilePhone" type="text" />
    </div>
    <div class="email">
      <el-input v-model="userInfoDate.email" type="text" />
    </div>
    <div class="birthday">
      <el-input v-model="userInfoDate.birthday" type="text" />
    </div>
    <div class="company">
      <el-input v-model="userInfoDate.company" type="text" />
    </div>
    <div>
      <el-button @click="userInfo">更新</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "userInfo",
  props: {
    userInfoDate: {},
  },
  data() {
    return {
      // userInfoDate: {
      //   realName: null,
      //   nickname: null,
      //   mobilePhone: null,
      //   email: null,
      //   sex: null,
      //   birthday: null,
      //   company: null
      // },
      optionSex: [
        {
          value: "选项1",
          label: "男",
        },
        {
          value: "选项2",
          label: "女",
        },
      ],
    };
  },
  watch: {
    userInfoDate() {
      this.$nextTick(() => {});
    },
  },
  methods: {
    //更新
    userInfo() {
      this.$store
        .dispatch("userInfo", {
          realName: this.userInfoDate.realName,
          nickname: this.userInfoDate.nickname,
          sex: this.userInfoDate.sex,
          mobilePhone: this.userInfoDate.mobilePhone,
          email: this.userInfoDate.email,
          birthday: this.userInfoDate.birthday,
          company: this.userInfoDate.company,
        })
        .then(({ success, msg }) => {
          if (!success) {
            this.$message.error(msg);
          } else {
            this.$message.success("更新成功");
          }
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.user-info {
  position: absolute;
  left: 800px;
  top: 400px;
  width: 400px;
  height: 400px;
  div {
    margin-bottom: 5px;
  }
  .img {
    width: 200px;
    height: 69px;
    background: black;
  }
}
</style>
