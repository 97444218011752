export const baseUrl = "https://api.mtiland.com/" //服务器根路径

export const LINKSHOW_BASE_URL_NAME = 'linkshow'
export const LINKPOINT_BASE_URL_NAME = 'linkpoint'

//需要登录
export const UN_NEED_LOGIN_ROUTER_NAMES = [
  "workspace", "invite"
]


//首页菜单模块
export const HEADER_MENUS = [
  {
    path: "/product",
    name: "product",
    children: "header-product",
    meta: {
      name: "产品中心",
    },

  },
  {
    name: "assets",
    path: "/assets",
    children: "header-assets",
    meta: {
      name: "解决方案",
    },

  },
  {
    path: "/learn",
    name: "learn",
    meta: {
      name: "经典案例",
    },

  },
  // {
  //   path: "/about-us",
  //   name: "about-us",
  //   meta: {
  //     name: "关于我们",
  //   },

  // },
  // {
  //   path: "/background",
  //   name: "background",
  //   meta: {
  //     name: "行业背景",
  //   },

  // },   
  {
    path: "/joinus",
    name: "joinus",
    meta: {
      name: "加入我们",
    },

  }
]
