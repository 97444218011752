<template>
  <el-radio-group v-model="radio1">
    <el-radio
      v-for="(item, index) in option"
      :key="index"
      @click.native.prevent="clickitem(index)"
      :label="index"
      >{{ item }}</el-radio
    >
  </el-radio-group>
</template>

<script>
export default {
  name: "customTab",
  props: {
    option: Array,
    selectIndex: Number,
  },
  data() {
    return {
      radio1: "",
    };
  },
  created() {
    this.radio1 = this.selectIndex;
  },
  methods: {
    clickitem(e) {
      this.radio1 = e;
      this.$emit("update:selectIndex", e);
    },
  },
};
</script>
<style lang="scss">
.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: 0 0 2px 2px #fff;
}
.el-radio-group {
  margin-top: 32px;
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  .el-radio {
    color: #606266;
    cursor: pointer;
    margin-right: 16px;
    margin-bottom: 40px;
  }
  .el-radio__inner:hover {
    border-color: #357aff;
  }
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
  .el-radio__label {
    font-size: 18px;
    line-height: 24px;
    color: #333439;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border: 1px solid #c2c2c2;
    background: none;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #333439;
  }
  .el-radio__inner::after {
    width: 10px;
    height: 10px;
    background-color: #357aff;
  }
}
</style>
<style lang="scss" scoped></style>
