import axios from "@/lib/axios"
import { unctionparamsToFormData } from "../lib/util"
export const test = () => {
    return axios.request({
        url: 'rest'
    })
}
//短信验证码获取
export const userSms = ({
    username
}) => {
    return axios.request({
        url: '/user/register',
        params: {
            username
        },
    })
}

//获取重置密码短信验证码
export const userPasswordReset = ({
    username
}) => {
    return axios.request({
        url: '/user/password/reset',
        params: {
            username
        },
    })
}

//通过短信验证码重置密码
export const passwordReset = ({
    username,
    newPassword,
    smsCode
}) => {
    return axios.request({
        url: '/user/password/reset',
        method: "put",
        data: unctionparamsToFormData({
            username, newPassword, smsCode
        }),
    })
}

//验证码获取
export const captcha = ({
    username
}) => {
    return axios.request({
        url: '/common/captcha',
        params: {
            username
        },
        responseType: "blob"
    })
}
//验证码校验
export const captchaPost = ({
    username, code
}) => {
    return axios.request({
        url: '/common/captcha',
        method: "post",
        data: unctionparamsToFormData({
            username, code
        }),
    })
}

//登录
export const login = ({
    username, password
}) => {
    return axios.request({
        url: '/user',
        method: "post",
        data: unctionparamsToFormData({
            username, password
        }),
    })
}

export const tryLogin = () => {
    return axios.request({
        url: '/user',
    })
}
//退出登录
export const exitLogin = () => {
    return axios.request({
        url: '/user',
        method: "DELETE"
    })
}
//注册
export const register = ({
    username, password, company, smsCode
}) => {
    return axios.request({
        url: '/user/register',
        method: "post",
        data: unctionparamsToFormData({
            username, password, company, smsCode
        }),
    })
}
//密码修改
export const userPassword = ({
    password,
    newPassword
}) => {
    return axios.request({
        url: '/user/password',
        method: "PUT",
        data: unctionparamsToFormData({
            password,
            newPassword
        })
    })
}

//更新用户信息
export const userInfoName = ({
    realName,
    nickname,
    sex,
    mobilePhone,
    email,
    birthday,
    company
}) => {
    return axios.request({
        url: '/user/info',
        method: "PUT",
        data: unctionparamsToFormData({
            // realName,
            nickname,
            // sex,
            // mobilePhone,
            // email,
            // birthday,
            // company
        })
    })
}

export const updateUserCover = (
    {
        file
    }
) => {
    return axios.request({
        url: '/user/avatar',
        method: "PUT",
        data: unctionparamsToFormData({
            avatar: file
        })
    })
}

// 招聘
export const selectAll = () => {
    return axios.request({
        url: "/JobCategory/selectAll",
    })
}
export const selectJobMessage = (jobCategoryId) => {
    return axios.request({
        url: "position/selectAll",
        params: {
            jobCategoryId
        }
    })
}

// 案例
export const getAllCaseLabel = () => {
    return axios.request({
        url: "/label/findAll"
    })
}
export const getDetialCase = (labelId) => {
    return axios.request({
        url: "/case/findAll",
        params: {
            labelId
        }
    })
}
export const getOneCase = (caseId) => {
    return axios.request({
        url: "/case/findByCaseId",
        params: {
            caseId
        }
    })
}