<template>
  <div style="background: #333439">
    <div class="footer w1120">
      <div class="foot-content">
        <div class="content-left">
          <div class="left-item">
            <div class="logo">
              <img src="../../assets/foot-logo.png" alt="" />
            </div>
            <div class="text">零探数字</div>
          </div>
          <div class="left-item left-topMargin">
            <div class="small-logo">
              <img src="../../assets/footer/tel.svg" alt="" />
            </div>
            <div class="small-text">159-4921-0837</div>
          </div>
          <div class="left-item" style="margin-top: 26px">
            <div class="small-logo">
              <img src="../../assets/footer/email.svg" alt="" />
            </div>
            <div class="small-text">lintech@linktwins.com</div>
          </div>
          <div class="left-item" style="margin-top: 26px">
            <div class="small-logo">
              <img src="../../assets/footer/map.svg" alt="" />
            </div>
            <div class="small-text">
              无锡经开区高浪路999号（无锡国家传感信息中心）D1栋901室
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="right-item" v-for="(item, key) of productList">
            <div class="item-top">{{ key }}</div>
            <div
              class="item-text"
              v-for="(i, index) of item"
              @click="toClickPage(i.path, i.query)"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="foot-text w1120">
      <p>© 2022 无锡零探数x字能源科技有限公司版权所有</p>
      <p @click="openOther">苏ICP备2020065273号-2</p>
      <p>公网安备 5299********93号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "customFooter",
  data() {
    return {
      productList: {
        产品中心: [
          { name: "灵思", path: "/productlinkMior", query: {} },
          { name: "灵点", path: "/productlinkPoint", query: {} },
          { name: "灵秀", path: "/productlinkShow", query: {} },
        ],
        解决方案: [
          { name: "数字孪生解决方案", path: "/wisdom-city/1", query: {} },
          { name: "VR解决方案", path: "/wisdom-city/9" },
          { name: "展厅解决方案", path: "/wisdom-city/11", query: {} },
          { name: "数字人解决方案", path: "/wisdom-city/7", query: {} },
        ],
        经典案例: [
          { name: "智慧工厂", path: "/learn", query: { select: 0 } },
          { name: "智慧园区", path: "/learn", query: { select: 1 } },
          { name: "智慧能源", path: "/learn", query: { select: 2 } },
          { name: "智慧文旅", path: "/learn", query: { select: 3 } },
        ],
        关于我们: [
          { name: "企业简介", path: "/about-us", query: {} },
          { name: "企业文化", path: "/about-us", query: {} },
          { name: "企业动态", path: "/about-us", query: {} },
        ],
        加入我们: [{ name: "职位招聘", path: "/joinus", query: {} }],
      },
    };
  },
  methods: {
    toClickPage(path, query) {
      this.$router.push({ path, query });
    },
    openOther() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  // height: 409px;
  padding-top: 77px;
  .foot-content {
    display: flex;
    .content-left {
      // margin-left: 294px;
      width: 360px;
      .left-item {
        display: flex;
        .logo {
          width: 97px;
          height: 38px;
          display: none;
        }
        .small-logo {
          width: 20px;
          height: 20px;
          text-align: center;
        }
        .text {
          color: white;
          margin-left: 7px;
          font-size: 18px;
          line-height: 41px;
        }
        .small-text {
          margin-left: 12px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: 350;
          font-size: 14px;
        }
      }
      .left-topMargin {
        margin-top: 18px;
      }
    }
    .content-right {
      text-align: center;
      color: white;
      display: flex;
      margin-top: 5px;
      .right-item {
        // width: 120px;
        margin-left: 77px;
        text-align: left;
        .item-top {
          font-size: 16px;
          margin-bottom: 14px;
        }
        .item-text {
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 20px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 82px;
  margin-bottom: 18px;
}
.foot-text {
  display: flex;
  p {
    cursor: default;
    font-weight: 350;
    font-size: 14px;
    color: #ffffff;
    margin-right: 79px;
    margin-bottom: 23px;
  }
}
</style>
