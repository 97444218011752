import axios from "axios"
// import QS from "qs"
import { baseUrl } from "@/constant/index"
import { getToken } from "../util";
const newAxios = axios.create({
    baseURL: baseUrl,
    timeout: 100000,
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }
})


//携带token
newAxios.interceptors.request.use(function(config) {
   config.headers['Authorization']=getToken()
    return config;
});




export default newAxios