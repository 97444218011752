<template>
  <div class="header-assets">
    <div
      class="header-assets-item"
      v-for="(item, index) in assetsSolution"
      :class="index == 0 ? 'asset1' : ''"
      :key="index"
    >
      <div class="item-text">
        {{ item.text }}
      </div>
      <div class="item-line"></div>
      <div
        class="item-detail-content"
        :class="index == 0 ? 'item-special-content' : ''"
      >
        <div
          class="detail-item"
          v-for="(i, ind) in item.detail"
          :key="ind + '111'"
        >
          <span v-if="i.params != 8" @click="toHref(i.toHref, i.params)">{{ i.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-assets",
  data() {
    return {
      assetsSolution: [
        {
          toHref: "",
          text: "数字孪生解决方案",
          detail: [
            {
              text: "智慧城市",
              toHref: "assets-wisdomcity",
              params: "1",
            },
            {
              text: "智慧文旅",
              toHref: "assets-wisdomcity",
              params: "2",
            },
            {
              text: "智慧园区",
              toHref: "assets-wisdomcity",
              params: "3",
            },
            {
              text: "智慧工厂",
              toHref: "assets-wisdomcity",
              params: "4",
            },
            {
              text: "智慧交通",
              toHref: "assets-wisdomcity",
              params: "5",
            },
            {
              text: "智慧能源",
              toHref: "assets-wisdomcity",
              params: "6",
            },
          ],
        },
        {
          text: "VR解决方案 ",
          detail: [
            {
              text: "VR虚拟教育",
              toHref: "assets-wisdomcity",
              params: "9",
            },
            {
              text: "VR职业实训",
              toHref: "assets-wisdomcity",
              params: "10",
            },
          ],
        },
        {
          text: "展厅解决方案",
          toHref: "",
          detail: [
            {
              text: "云上展厅",
              toHref: "assets-wisdomcity",
              params: "11",
            },
            {
              text: "企业驾驶舱",
              toHref: "assets-wisdomcity",
              params: "12",
            },
          ],
        },
        {
          text: "数字人解决方案",
          toHref: "",
          detail: [
            {
              text: "写实3D虚拟人",
              toHref: "assets-wisdomcity",
              params: "7",
            },
            {
              text: "卡通2.5D虚拟人",
              toHref: "assets-wisdomcity",
              params: "8",
            },
          ],
        },
      ],
    };
  },
  methods: {
    toHref(name, params) {
      this.$router.push({
        name,
        params: {
          id: params,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-assets {
  padding: 36px 32px;
  display: grid;
  grid-template-columns: 220px 220px 220px 220px;
  // grid-template-rows: 46px 46px 0px 0px;
  grid-gap: 52px;
  width: 1088px;
  height: 253px;

  .header-assets-item {
    color: #333439;
    .item-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #333439;
    }
    .item-line {
      margin-top: 12px;
      width: 100%;
      height: 1px;
      // background: #000;
      background: rgba(0, 0, 0, 0.1);
    }
    .detail-item:hover {
      span {
        width: 100%;
        background: rgba(53, 122, 255, 0.1);
        padding: 6px 0px;
        color: #357aff;
      }
    }
    .item-special-content {
      display: grid;
      grid-template-columns: 84px 84px;
      grid-gap: 0px 23px;
    }
    .item-detail-content {
      margin-top: 32px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 52, 57, 0.7);
      .detail-item {
        cursor: pointer;
        line-height: 20px;
        // letter-spacing: 1px;
        margin-bottom: 22px;
      }
    }
  }
}
</style>
