<style lang="scss">
.workspace-header {
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  background: #242424;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 24px;
  .workspace-header-left {
    display: flex;
    align-items: center;
    .logo {
      margin-right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity: 0.6;
      .title1 {
        font-size: 16px;
        color: white;
        line-height: 23px;
      }
      .title2 {
        font-size: 14px;
        color: white;
      }
    }
  }
  .workspace-header-user {
    width: 30px;
    height: 30px;
    background: rgb(83, 83, 83);
    border-radius: 50%;
    cursor: pointer;
  }
}

.center0te {
  font-size: 14px;
  color: white;
  opacity: 0.6;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.header-right:hover .menu {
  display: block;
}
.header-right {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  .user-cover {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: #777777;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-size: cover;
  }
  .menu {
    width: 80px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #2e2f33;
    font-size: 12px;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    margin-top: -8px;
    display: none;
    z-index: 09999;
    right: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    .menu-item {
      color: white;
      font-size: 12px;
      opacity: 0.6;
      height: 24px;
      margin-left: 4px;
      margin-right: 4px;
      cursor: pointer;
      display: flex;
      border-radius: 4px;

      margin-bottom: 5px;
      align-items: center;
      justify-content: center;
      &:hover {
        image-orientation: 0.8;
        background: #434448;
      }
    }
  }
}
// .custom-dropdown {
//   background: #2e2f33;
//   color: #fff;
//   width: 80px;
//   border: none;
//   .el-dropdown-menu__item {
//     color: #fff;
//     margin-left: 5px;
//     margin-right: 5px;
//     padding-left: 10px;
//     padding-right: 10px;
//     opacity: 0.6;
//     font-size: 12px;
//     border-radius: 5px;
//     &:hover {
//       background: #434448;
//     }
//   }
//   .el-popper {
//     border: none;
//   }
//   border: none;
//   /deep/ .popper__arrow {
//     display: none !important;
//   }
// }
</style>

<template>
  <div class="workspace-header noauto">
    <div class="workspace-header-left">
      <div class="logo">
        <img
          src="./assets/linkpint.png"
          v-if="projectType == 'linkpoint'"
          alt=""
        />
        <img
          src="./assets/linkshow.png"
          v-if="projectType == 'linkshow'"
          alt=""
        />
      </div>

      <div class="name"></div>
    </div>

    <div class="center0te">
      {{ projectName }}
    </div>

    <!-- >
          <el-dropdown-item @click.native="toWorkSpace"
            >工作台</el-dropdown-item
          >
          <el-dropdown-item @click.native="exitLogin"
            >退出登录</el-dropdown-item
          > -->

    <div class="header-right">
      <div
        class="user-cover"
        v-if="userInfo.extInfo"
        :style="`background-image:url(${userInfo.extInfo.avatarUrl})`"
      ></div>
      <div class="menu">
        <div class="menu-item" @click="toWorkSpace">工作台</div>
        <div class="menu-item" @click="exitLogin">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
const baseUrl = "https://api.mtiland.com";

const exitLogin = () => {
  fetch(baseUrl + "/user", {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });

  localStorage.removeItem("token");

  location.href = location.origin;
};

const tryLogin = (cb) => {
  fetch(baseUrl + "/user", {
    method: "get",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  }).then((json) => {
    json.json().then((res) => {
      if (res.errorCode !== "200") {
        // localStorage.removeItem("token");
        location.href =
          location.origin + "/#/?needLogin=true&linkHref=" + location.href;
      } else {
        cb(res);
      }
    });
  });
};

const getProject = (id, cb) => {
  let name = "linkpoint";
  fetch(baseUrl + `/${name}?projectId=${id}`, {
    method: "get",

    headers: {
      Authorization: localStorage.getItem("token"),
    },
  }).then((json) => {
    json.json().then((res) => {
      if (res.errorCode == "500") {
        cb(null);
      }

      if (res.errorCode == "200") {
        cb(res);
      }

      if (res.errorCode == "400") {
        cb(false);

        location.href = location.origin;
      }
    });
  });
};

export default {
  name: "link-public-header",
  props: {
    projectName: {
      type: String,
      default: "",
    },
    tryRefresh: {
      type: Boolean,
      default: false,
    },
    projectType: String,
  },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    exitLogin() {
      exitLogin();
    },
    toWorkSpace() {
      window.location = location.origin + "/workspace/";
    },
    async getProject() {
      return new Promise((resolve) => {
        getProject(this.$route.query.id, (res) => {
          if (res === false) {
            location.href = location.origin;
          } else {
            resolve(res);
          }
        });
      });
    },
  },
  created() {
    tryLogin((res) => {
      this.userInfo = res.data.userInfo;
      // if (this.tryRefresh == true) {
      //   return;
      // }
    });
  },
};
</script>
