<template>
  <div id="app" @dragstart.prevent>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  created() {
    this.$store.dispatch("tryLogin").then(({ success, msg }) => {
      if (!success) {
        this.$message.error(msg);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  min-width: 1200px;
  height: calc(100%);
}
</style>
