<template>
  <div>
    <div
      class="custom-header"
      :style="{ left: `-${header_left}px` }"
      :class="isChangeHead ? `white-bg` : ''"
    >
      <div class="custom-header-left" @click="linkTo('home')">
        <div class="logo"></div>
        <div class="title">
          无锡零探数字能源科技有限公司
        </div>
      </div>
      <div class="custom-header-right">
        <div
          class="menu-item"
          v-for="menu of HEADER_MENUS"
          :key="menu.meta.name"
          @click="linkTo(menu.name, menu.children ? true : false)"
          :id="$route.name.includes(menu.name) ? 'menu-item-active' : ''"
        >
          <!-- 子组件 -->
          <template v-if="menu.children">
            <div class="child-view">
              <component :is="menu.children"></component>
            </div>
          </template>

          <span> {{ menu.meta.name }}</span>
        </div>

        <!--  未登录 -->
        <template v-if="!$store.state.isLogin">
          <div class="login-button" @click="login">登录/注册</div>
        </template>

        <!-- 登录 -->
        <template v-else>
          <div @click="linkToWorkspace" class="control-button">
            <img src="@/assets/control.svg" alt="" />
            工作台
          </div>

          <div class="user-info">
            <el-dropdown>
              <div
                class="user-cover"
                :style="
                  `background-image:url(${$store.state.userInfo.extInfo.avatarUrl})`
                "
              ></div>
              <el-dropdown-menu slot="dropdown" trigger="click">
                <el-dropdown-item @click.native="exitLogin"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </div>
    </div>
    <div class="login-register-container" v-if="isContent">
      <div class="test" @click="exit">
        <LoginRegister @click.native.stop></LoginRegister>
      </div>
    </div>
  </div>
</template>

<script>
import { HEADER_MENUS } from "@/constant";
import LoginRegister from "../login-register/index.vue";
export default {
  name: "customHeader",
  data() {
    return {
      HEADER_MENUS,
      isContent: false,
    };
  },
  props: {
    isChangeHead: Boolean,
    isLoginUi: Boolean,
    header_left: Number,
  },
  watch: {
    isLoginUi(newVal, oldVal) {
      this.isContent = newVal;
    },
  },
  methods: {
    exit() {
      if (this.$route.query.needLogin) {
        this.isContent = false;
        this.$router.push({ path: "/" });
        return;
      }
      document.body.style.overflow = "auto";
      this.isContent = false;
    },
    login() {
      this.isContent = true;
      document.body.style.overflow = "hidden";
    },
    linkTo(name, boolean) {
      if (!name) {
        return;
      }
      if (boolean == true) {
        return;
      }
      if (name == "learn") {
        this.$router.replace({
          name,
          query: {
            isHome: true,
          },
        });
        return;
      }
      this.$router.replace({
        name,
      });
    },
    exitLogin() {
      this.$store.dispatch("exitLogin").then((result) => {});
      this.isContent = false;
      this.$router.push("/");
    },
    //跳转控制台
    linkToWorkspace() {
      // this.$router.push({
      //   name: "workspace",
      // });
      location.href = location.origin + "/workspace/";
    },
  },
  created() {
    this.isContent = this.isLoginUi;
  },
  components: { LoginRegister },
};
</script>

<style lang="scss" scoped>
.child-view {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 60px;
  background: rgba(255, 255, 255, 0.9);
  cursor: default;
  display: none;
}

.custom-header {
  position: fixed;
  color: #000000;
  z-index: 1001;
  top: 0;
  min-width: 1200px;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 66px;
  .custom-header-left {
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      width: 21px;
      height: 24px;
      background-image: url("../../assets/logo.png");
      background-size: cover;
    }
    .title {
      white-space: nowrap;
      font-weight: 400;
      font-size: 18px;
      line-height: 41px;
      margin-left: 15px;
    }
  }

  .custom-header-right {
    color: #333439 !important;
    display: flex;
    align-items: center;
    height: 100%;
    align-items: center;
    .menu-item {
      white-space: nowrap;
      cursor: pointer;
      margin-left: 64px;
      font-size: 18px;
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      position: relative;
      border-bottom: 2px solid transparent;
      height: 100%;
      span {
        margin-top: 4px;
      }
      &:hover {
        color: #357aff;
        border-bottom: 2px solid #357aff;
        .child-view {
          display: block;
        }
      }
    }

    #menu-item-active {
      color: #357aff;
      border-bottom: 2px solid #357aff;
    }
    .header-one {
      margin-right: 40px;
      background-image: url("../../assets/one.png");
      width: 40px;
      height: 40px;
      background-size: cover;
    }
    .control-button {
      width: 112px;
      height: 36px;
      background: #357aff;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-left: 64px;
      margin-right: 16px;
      img {
        width: 20.87px;
        height: 20.87px;
        margin-right: 9.5px;
      }
    }
    .login-button {
      width: 112px;
      height: 36px;
      background: #357aff;
      border-radius: 8px;
      margin-left: 64px;
      // margin-top: -2px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 32px;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #fff;
    }

    .user-info {
      color: white;
      display: flex;
      align-items: center;
      margin-right: 32px;
      .user-name {
        margin-right: 20px;
      }

      .user-cover {
        width: 36px;
        height: 36px;
        background: rgba(196, 196, 196);
        cursor: pointer;
        border-radius: 50%;
        background-size: cover;
      }
    }
  }
}
.white-bg {
  background-color: #ffffff;
}
.login-register-container {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  // left: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  // width: 100vw;
  .test {
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }
}
</style>
