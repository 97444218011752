<template>
  <div class="title-content">
    <div class="content-text" :style="`color:${color}`">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "lineTitle",
  props: {
    title: String,
    color: String,
    lineColor: String,
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    width: 360px;
    height: 2px;
    background: #c4c4c4;
  }
  .content-text {
    font-size: 40px;
    font-weight: 500;
    padding: 0 21px;
  }
}
</style>
