import store from "vuex";
import Vue from "vue";
import { getToken, setToken } from "../lib/util";
Vue.use(store);

import {
  login,
  captchaPost,
  tryLogin,
  exitLogin,
  register,
  userPassword,
} from "@/api";

import router from "@/router";

export default new store.Store({
  state: {
    userInfo: {
      username: "",
      password: "",
    },
    isLogin: false,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      state.isLogin = true;
    },
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
      if (!isLogin) {
        setToken();
        state.userInfo = {};
      }
    },
  },
  actions: {
    exitLogin(state) {
      return new Promise(async (resolve) => {
        let result = await exitLogin();
        state.commit("setIsLogin", false);
      });
    },
    register(state, { username, password, company, smsCode }) {
      return new Promise(async (resolve) => {
        let result2 = await register({ username, password, company, smsCode });
        if (result2.data.errorCode != "200") {
          resolve({ success: false, msg: result2.data.errorMsg });
        } else {
          resolve({ success: true });
        }
      });

      // login(username,password)
    },
    userPassword(state, { username, password, code, newPassword }) {
      let result = userPassword({ password, newPassword });
      return new Promise(async (resolve) => {
        let result2 = await userPassword({ password, newPassword });
        if (result2.data.errorCode != "200") {
          resolve({ success: false, msg: result2.data.errorMsg });
        } else {
          // setToken(result2.data.data.token);
          // state.commit("setUserInfo", result2.data.data.userInfo);
          resolve({ success: true });
        }
      });

      // login(username,password)
    },
    login(state, { username, password, code }) {
      return new Promise(async (resolve) => {
        let result1 = await captchaPost({ username, code });
        if (result1.data.errorCode != "200") {
          resolve({ success: false, msg: result1.data.errorMsg });
        }
        let result2 = await login({ username, password });
        if (result2.data.errorCode != "200") {
          resolve({ success: false, msg: result2.data.errorMsg });
        } else {
          setToken(result2.data.data.token);
          state.commit("setUserInfo", result2.data.data.userInfo);
          resolve({ success: true });
        }
      });

      // login(username,password)
    },
    //更新用户信息
    userInfo(
      state,
      {
        username,
        code,
        realName,
        nickname,
        sex,
        mobilePhone,
        email,
        birthday,
        company,
      }
    ) {
      return new Promise(async (resolve) => {
        let result2 = await userInfo({
          realName,
          nickname,
          sex,
          mobilePhone,
          email,
          birthday,
          company,
        });
        if (result2.data.errorCode != "200") {
          resolve({ success: false, msg: result2.data.errorMsg });
        } else {
          // setToken(result2.data.data.token);
          // state.commit("setUserInfo", result2.data.data.userInfo);
          resolve({ success: true });
        }
      });
    },

    tryLogin(state) {
      return new Promise(async (resolve) => {
        if (!getToken()) return;


        tryLogin().then((result) => {
          if (result.data.errorCode != "200") {
            state.commit("setIsLogin", false);
            router.push({
              name: "home",
            });
            resolve({ success: false, msg: result.data.errorMsg });
          } else {
            state.commit("setUserInfo", result.data.data.userInfo);
            resolve({ success: true });

          }
        });

        // login(username,password)
      });
    },
  },
  modules: {},
});
