import Vue from "vue";
// import customHeader from "./custom-header";
// import loginContainer from "./login-container";
// import registerContainer from "./register-container";
// import editPassword from "./edit-password";
// import userInfo from './user-info'
// import homeShow from './home-show'

// Vue.component("customHeader", customHeader);
// Vue.component("loginContainer", loginContainer);
// Vue.component("registerContainer", registerContainer);
// Vue.component("editPassword", editPassword);
// Vue.component("userInfo", userInfo);
// Vue.component("homeShow", homeShow);


//自动注册组件
const componentsContext = require.context("../components", true, /index.vue$/)
componentsContext.keys().forEach(component => {
        // 获取文件中的 default 模块
    const componentConfig = componentsContext(component).default
    Vue.component(componentConfig.name, componentConfig)
})

