export default [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index"),
    children: [
      {
        path: "",
        name: "home",
        meta: {
          name: "首页",
        },
        component: () => import("../views/index/views/home"),
      },
      {
        path: "/productlinkPoint",
        name: "product-linkPoint",
        component: () => import("../views/index/views/product/linkPoint"),
      },
      {
        path: "/productlinkMior",
        name: "product-linkMior",
        component: () => import("../views/index/views/product/linkMior"),
      },
      {
        path: "/wisdom-city/:id",
        name: "assets-wisdomcity",
        component: () => import("../views/index/views/assets/wisdom-city"),

      },
      {
        path: "/productlinkShow",
        name: "product-linkShow",
        component: () => import("../views/index/views/product/linkShow"),
      },
      {
        path: "/assets",
        name: "assets",
        meta: {
          name: "解决方案",
        },
        component: () => import("../views/index/views/assets"),
      },

      {
        path: "/product",
        name: "product",
        meta: {
          name: "产品中心",
        },
        component: () => import("../views/index/views/product"),

      },
      {
        path: "/learn",
        redirect: '/learn/home',
        name: "learn",
        meta: {
          name: "支持与培训",
        },
        component: () => import("../views/index/views/learn"),
        children: [
          {
            path: "/learn/home",
            name: "learn-case-home",
            component: () => import('../views/index/views/learn/home.vue'),
            meta: {
              name: "经典案例",
              keepAlive: true
            }
          },
          {
            path: "/learn/detial",
            name: "learn-case-detial",
            component: () => import('../views/index/views/learn/detial.vue'),
            meta: {
              name: "案例详情"
            }
          }
        ]
      },
      {
        path: "/about-us",
        name: "about-us",
        meta: {
          name: "关于我们",
        },
        component: () => import("../views/index/views/about-us"),

      }, {
        path: "/background",
        name: "background",
        meta: {
          name: "行业背景",
        },
        component: () => import("../views/index/views/background"),

      },
      {
        path: "/joinus",
        name: "joinus",
        meta: {
          name: "加入我们",
        },
        component: () => import("../views/index/views/joinus"),

      }
    ]
  },
  // {
  //   path: "/workspace",
  //   name: "workspace",
  //   component: () => import("../views/workspace")
  // },
  // {
  //   path: "/invite",
  //   name: "invite",
  //   component: () => import("../views/invite")
  // },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test")
  }
];
