<template>
  <div class="noC-forget">
    <div class="noC-login-item">
      重置密码
    </div>
    <div class="noC-form-content">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="username">
          <div class="noC-item">
            <div class="noC-item-name">手机号码</div>
            <el-input
              v-model="form.username"
              type="text"
              @focus="blurSearchFor('username')"
              @blur="checkError('username')"
              :placeholder="placeholder"
            />
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <div class="noC-item">
            <div class="noC-item-name">设置新密码</div>
            <el-input
              v-model="form.password"
              :type="isShowPassword ? 'text' : 'password'"
              @focus="blurSearchFor('password')"
              @blur="checkError('password')"
              :placeholder="placeholderPassword"
            />
            <div
              class="iconfont noC-icon-set"
              @click="showPass"
              :class="isShowPassword ? 'icon-eye' : 'icon-no_eye'"
            ></div>
          </div>
        </el-form-item>
        <el-form-item prop="newPassword">
          <div class="noC-item">
            <div class="noC-item-name">确认新密码</div>
            <el-input
              v-model="form.newPassword"
              :type="isShowNewPassword ? 'text' : 'password'"
              @focus="blurSearchFor('newPassword')"
              @blur="checkError('newPassword')"
              :placeholder="placeholderNewPassword"
            />
            <div
              class="iconfont  noC-icon-set"
              @click="isShowNewPassword = !isShowNewPassword"
              :class="isShowNewPassword ? 'icon-eye' : 'icon-no_eye'"
            ></div>
          </div>
        </el-form-item>
        <div class="noC-item">
          <div class="noC-item-name">手机验证码</div>
          <div class="noC-img-item">
            <el-input
              placeholder="验证码"
              type="text"
              v-model="form.sms"
              clearable
            >
              <template slot="append">
                <div
                  class="noC-sms"
                  @click="send_sms"
                  ref="sms"
                  :disabled="isSms"
                >
                  {{ sms_interval }}
                </div>
              </template>
            </el-input>
          </div>
        </div>
      </el-form>
      <!-- <step-one v-if="isStep1"> </step-one>
      <step-two v-if="isStep2"></step-two> -->
      <div class="noC-item-login" @click="reset">确认</div>
    </div>
  </div>
</template>

<script>
import { userPasswordReset, passwordReset } from "@/api";
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
let checkPassword = (rule, value, callback) => {
  let reg = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/;
  if (!reg.test(value)) {
    callback(new Error("请输入8-16位,必须包含数字、大小写字母"));
  } else {
    callback();
  }
};
export default {
  name: "registerContainer",
  data() {
    return {
      placeholder: "请您输入手机号",
      placeholderPassword: "8-16位,必须包含数字、大小写字母",
      placeholderNewPassword: "8-16位,必须包含数字、大小写字母",
      isShowPassword: false, //密码小眼睛
      isShowNewPassword: false, //重复密码小眼睛
      isSms: false,
      form: {
        username: "",
        password: "",
        sms: "",
        newPassword: "",
      },
      rules: {
        username: [
          {
            type: "number",
            validator: checkPhone,
            message: "请填写正确手机号码",
            trigger: "blur",
          },
        ],
        password: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: checkPassword,
            message: "请输入8-16位,必须包含数字、大小写字母",
            trigger: "blur",
          },
        ],
        newPassword: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: checkPassword,
            message: "请输入8-16位,必须包含数字、大小写字母",
            trigger: "blur",
          },
        ],
      },
      sms_interval: "获取验证码",
      // stepData: [
      //   {
      //     num: 1,
      //     name: "验证手机",
      //   },
      //   {
      //     num: 2,
      //     name: "设置密码",
      //   },
      //   {
      //     num: 3,
      //     name: "重新登录",
      //   },
      // ],
    };
  },
  methods: {
    showPass() {
      this.isShowPassword = !this.isShowPassword;
    },
    checkError(i) {
      if (i == "username") {
        this.$refs.form.validateField(i, (valid) => {
          if (valid) {
            this.form[i] = "";
            return;
          }
        });
      } else if (i == "password") {
        this.$refs.form.validateField(i, (valid) => {
          if (valid) {
            this.form[i] = this.form.password;
            return;
          }
        });
      } else {
        if (this.form.password != this.form.newPassword) {
          this.$message.error("两次密码输入不一致");
        }
        this.$refs.form.validateField(i, (valid) => {
          if (valid) {
            return;
          }
        });
      }
    },
    blurSearchFor(i) {
      this.$refs.form.validateField(i, (valid) => {
        if (valid) {
          // this.form[i] = "";
          this.$refs.form.clearValidate(i);
          return;
        }
      });
      if (i == "username") {
        if (this.placeholder == "请您输入手机号") {
          this.placeholder = "";
        }
      } else if (i == "password") {
        if (this.placeholderPassword == "8-16位,必须包含数字、大小写字母") {
          this.placeholderPassword = "";
        }
      } else {
        if (this.placeholderNewPassword == "8-16位,必须包含数字、大小写字母") {
          this.placeholderNewPassword = "";
        }
      }
    },
    send_sms() {
      let reg1 = /^1[345789]\d{9}$/;
      let reg2 = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/;
      if (!reg1.test(this.form.username)) {
        this.$message.error("请填写正确手机号码");
        return;
      } else if (!reg2.test(this.form.password)) {
        this.$message.error("请输入密码,8-16位,必须包含数字、大小写字母");
        return;
      } else if (!reg2.test(this.form.newPassword)) {
        this.$message.error("请再次输入密码,8-16位,必须包含数字、大小写字母");
        return;
      } else if (this.form.password != this.form.newPassword) {
        this.$message.error("请输入相同的密码");
        return;
      } else {
        //向后端发送短信接口发请求，给这个手机号发短信
        userPasswordReset({
          username: this.form.username,
        }).then((result) => {
          if (result.data.errorCode == 200) {
            this.$message({ message: result.data.errorMsg, type: "success" });
            this.isSms = true;
            this.sms_interval = "发送中...";
            let sms_interval_time = 120;
            // this.isSms = true;
            let timer = setInterval(() => {
              if (sms_interval_time <= 1) {
                clearInterval(timer);
                // this.sms_interval = "获取验证码";
              } else {
                sms_interval_time -= 1;
                this.sms_interval = `${sms_interval_time}秒`;
              }
            }, 1000);
          } else {
            this.sms_interva = "获取验证码";
            this.$message.error(result.data.errorMsg);
          }
        });
        return;
      }
    },
    reset() {
      if (
        !(this.form.username,
        this.form.password,
        this.form.sms,
        this.form.newPassword)
      ) {
        this.$message.error("手机号，验证码，密码，新密码不能为空");
        return;
      }
      passwordReset({
        username: this.form.username,
        newPassword: this.form.password,
        smsCode: this.form.sms,
      }).then(({ data }) => {
        if (this.form.password != this.form.newPassword) {
          this.$message.error("两次密码输入不一致,请重新输入");
        } else {
          let { success, errorMsg } = data;
          if (!success) {
            this.$message.error(errorMsg);
          } else {
            this.$message.success("密码重置成功");
            this.$router.push("/");
            this.$parent.$parent.isContent = false;
            this.$parent.isLoginSys = true;
            // this.$parent.isReg = false;
            // this.$parent.isLoginSys = false;
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.noC-icon-set {
  display: inline;
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
}
.noC-processed {
  background: #d75529 !important;
}
.noC-forget {
  padding: 56px 102px;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 12px;
  .noC-login-item {
    text-align: center;
    color: #000000;
    font-size: 24px;
    line-height: 35px;
  }

  .noC-item {
    width: 396px;
    margin-bottom: 25px;
    .noC-item-name {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 20px;
    }
    .noC-sms {
      color: #357aff;
      cursor: pointer;
      // display: inline-block;
      border: none;
      width: 70px;
      text-align: center;
    }
    .noC-img-item {
      display: flex;
      height: 50px;
      width: 396px;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 8px;
      overflow: hidden;
      .noC-input-img {
        width: 200px;
      }
      .noC-img {
        width: 80px;
        height: 40px;
        margin-left: 100px;
        margin-top: 5px;
        flex-shrink: 0;
        background-color: #f7e8e3;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .noC-item-login {
    margin-top: 71px;
    margin-bottom: 83px;
    width: 396px;
    height: 50px;
    background: #357aff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 10px;
    font-weight: 400;
    font-size: 22px;
    line-height: 21px;
    cursor: pointer;
    color: #ffffff;
  }
}
</style>
