import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import { UN_NEED_LOGIN_ROUTER_NAMES } from "@/constant"
import { getToken } from "../lib/util";
import routes from "./routes"

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
let router = new Router({
  routes,
});


router.beforeEach((to, from, next) => {

  if (UN_NEED_LOGIN_ROUTER_NAMES.indexOf(to.name) >= 0) {
    if (!getToken()) {
      next({
        path: "/",
        query: {
          "needLogin": true,
          "linkHref": location.origin + "/#" + to.fullPath
        }
      })
      next()
    } else {
      next()
    }
    return
  }

  next()

})
router.afterEach((to, from, next) => {

})

window.router = router


export default router
window.router = router