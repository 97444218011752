import moment from "moment"


export const getTime = () => {
    let date = new Date();
    return {
        today: moment(date).format("YYYY-MM-DD")
    }
}


export const getToken = () => localStorage.getItem("token")

export const setToken = (token = '') => localStorage.setItem("token", token)

export const getDateToNewData = (time) => {
    time=new Date(time)
    var diff = '';
    var time_diff = new Date().getTime() - time; //时间差的毫秒数 

    //计算出相差天数 
    var days = Math.floor(time_diff / (24 * 3600 * 1000));
    if (days > 0) {
        diff += days + '天';
    }
    //计算出小时数 
    var leave1 = time_diff % (24 * 3600 * 1000);
    var hours = Math.floor(leave1 / (3600 * 1000));
    if (hours > 0) {
        diff += hours + '小时';
    } else {
        if (diff !== '') {
            diff += hours + '小时';
        }
    }
    //计算相差分钟数 
    var leave2 = leave1 % (3600 * 1000);
    var minutes = Math.floor(leave2 / (60 * 1000));
    if (minutes > 0) {
        diff += minutes + '分';
    } else {
        if (diff !== '') {
            diff += minutes + '分';
        }
    }
    //计算相差秒数 
    var leave3 = leave2 % (60 * 1000);
    var seconds = Math.round(leave3 / 1000);
    if (seconds > 0) {
        diff += seconds + '秒';
    } else {
        if (diff !== '') {
            diff += seconds + '秒';
        }
    }
    if(!diff){
        diff="0秒"
    }
    return diff + "前";
}

export const unctionparamsToFormData = (obj) => {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
            obj[key].forEach((item) => {
                formData.append(key, item);
            });
            return;
        }
        formData.append(key, obj[key]);
    });
    return formData
}