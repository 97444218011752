<template>
  <div class="noC-register">
    <!-- <div class="close" @click="close"></div> -->
    <div class="noC-login-item">
      <div class="noC-login-logo"></div>
      <div class="noC-login-name">无锡零探数字能源科技有限公司</div>
    </div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="username">
        <div class="noC-item">
          <div class="noC-item-name">手机号码</div>
          <el-input
            v-model="form.username"
            type="text"
            @focus="blurSearchFor('username')"
            @blur="checkError('username')"
            :placeholder="placeholder"
          />
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="noC-item">
          <div class="noC-item-name">设置新密码</div>
          <el-input
            v-model="form.password"
            :type="isShowPassword ? 'text' : 'password'"
            @focus="blurSearchFor('password')"
            @blur="checkError('password')"
            :placeholder="placeholderPassword"
          />
          <div
            class="iconfont noC-icon-set"
            @click="isShowPassword = !isShowPassword"
            :class="isShowPassword ? 'icon-eye' : 'icon-no_eye'"
          ></div>
        </div>
      </el-form-item>
      <el-form-item prop="company">
        <div class="noC-item">
          <div class="noC-item-name">公司名称</div>
          <el-input
            v-model="form.company"
            type="text"
            placeholder="请输入公司信息"
          />
        </div>
      </el-form-item>
      <div class="noC-item">
        <div class="noC-item-name">手机验证码</div>
        <div class="noC-img-item">
          <el-input
            placeholder="验证码"
            type="text"
            v-model="form.sms"
            clearable
          >
            <template slot="append">
              <div
                class="noC-sms"
                @click="send_sms"
                ref="sms"
                :disabled="isSms"
              >
                {{ sms_interval }}
              </div>
            </template>
          </el-input>
        </div>
      </div>
      <div>
        <slot name="header"></slot>
        <div class="noC-item-login" @click="register">
          <div>注册</div>
        </div>
        <slot name="bottom"></slot>
      </div>
    </el-form>
  </div>
</template>

<script>
import { userSms } from "@/api";
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
let checkPassword = (rule, value, callback) => {
  let reg = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/;
  if (!reg.test(value)) {
    callback(new Error("请输入8-16位,必须包含数字、大小写字母"));
  } else {
    callback();
  }
};
export default {
  name: "registerContainer",
  data() {
    return {
      isShowPassword: false, //小眼睛
      placeholder: "请您输入手机号",
      placeholderPassword: "8-16位,必须包含数字、大小写字母",
      isSms: false, //是否发送验证码
      form: {
        username: "",
        password: "",
        sms: "",
        company: "",
      },
      rules: {
        username: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请填写正确手机号码",
            trigger: "blur",
          },
        ],
        company: [
          { required: true, message: "请输入公司信息", trigger: "blur" },
        ],
        password: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: checkPassword,
            message: "请输入8-16位,必须包含数字、大小写字母",
            trigger: "blur",
          },
        ],
      },
      sms_interval: "获取验证码",
    };
  },
  methods: {
    checkError(i) {
      if (i == "username") {
        this.$refs.form.validateField(i, (valid) => {
          if (valid) {
            this.form[i] = "";
            return;
          }
        });
      } else {
        this.$refs.form.validateField(i, (valid) => {
          if (valid) {
            // this.form[i] = "";
            return;
          }
        });
      }
    },
    blurSearchFor(i) {
      this.$refs.form.validateField(i, (valid) => {
        if (valid) {
          this.form[i] = "";
          this.$refs.form.clearValidate(i);
          return;
        }
      });
      if (i == "username") {
        if (this.placeholder == "请您输入手机号") {
          this.placeholder = "";
        }
      } else if (i == "password") {
        if (this.placeholderPassword == "8-16位,必须包含数字、大小写字母") {
          this.placeholderPassword = "";
        }
      }
    },
    send_sms() {
      let reg1 = /^1[345789]\d{9}$/;
      let reg2 = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/;
      if (!reg1.test(this.form.username)) {
        this.$message.error("请填写正确手机号码");
        return;
      } else if (!reg2.test(this.form.password)) {
        this.$message.error("请输入密码,8-16位,必须包含数字、大小写字母");
        return;
      } else if (!this.form.company) {
        this.$message.error("请填写公司信息");
        return;
      } else if (!this.$parent.checked) {
        this.$message.error("请勾选用户协议");
      } else {
        userSms({
          username: this.form.username,
        }).then((result) => {
          if (result.data.errorCode == 200) {
            this.$message({ message: result.data.errorMsg, type: "success" });
            this.isSms = true;
            this.sms_interval = "发送中...";
            let sms_interval_time = 120;
            // this.isSms = true;
            let timer = setInterval(() => {
              if (sms_interval_time <= 1) {
                clearInterval(timer);
                // this.sms_interval = "获取验证码";
                // this.is_send = true; // 重新回复点击发送功能的条件
              } else {
                sms_interval_time -= 1;
                this.sms_interval = `${sms_interval_time}秒`;
              }
            }, 1000);
          } else {
            this.sms_interva = "获取验证码";
            this.$message.error(result.data.errorMsg);
          }
        });
        return;
      }
    },
    register() {
      if (
        !(this.form.username,
        this.form.password,
        this.form.sms,
        this.form.company)
      ) {
        this.$message.error("手机号，验证码，密码,公司信息不能为空");
        return;
      }
      if (!this.$parent.checked) {
        this.$message("请同意用户服务协议");
        return;
      }
      this.$store
        .dispatch("register", {
          password: this.form.password,
          username: this.form.username,
          company: this.form.company,
          smsCode: this.form.sms,
        })
        .then(({ success, msg }) => {
          if (!success) {
            this.$message.error(msg);
          } else {
            this.$store.state.userInfo.username = this.form.username;
            this.$store.state.userInfo.password = this.form.password;
            this.$message.success("注册成功,请登录！");
            this.$router.push("/");
            this.$parent.isReg = false;
            this.$parent.isLoginSys = true;

            // this.$parent.isLoginSys = false;
          }
        });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.noC-icon-set {
  display: inline;
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
}
.noC-disable {
  // background-color: #dddcdc !important;
  pointer-events: none;
}
.noC-auto-btn {
  pointer-events: auto;
}
.noC-register {
  padding: 60px 102px;
  background: #ffffff;
  border: 1px solid #dadada;
  // box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.2), inset -8px -6px 80px #FFFFFF;
  box-sizing: border-box;
  border-radius: 12px;
  .noC-close {
    position: relative;
    width: 16px;
    height: 16px;
    left: 459px;
    top: -4px;
    cursor: pointer;
  }
  .noC-login-item {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .noC-login-logo {
      width: 88px;
      height: 22px;
      background-image: url("../../assets/logo.png");
      background-size: cover;
    }
    .noC-login-name {
      font-weight: 400;
      font-size: 18px;
      line-height: 41px;
      margin-left: 16px;
    }
  }

  .noC-item {
    width: 396px;
    margin-bottom: 25px;
    .noC-item-name {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 20px;
    }
    .noC-sms {
      color: #357aff;
      cursor: pointer;
      // display: inline-block;
      border: none;
      width: 70px;
      text-align: center;
    }
    .noC-img-item {
      display: flex;
      height: 50px;
      width: 396px;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 8px;
      overflow: hidden;
      .noC-input-img {
        width: 200px;
      }
      .noC-img {
        width: 80px;
        height: 40px;
        margin-left: 100px;
        margin-top: 5px;
        flex-shrink: 0;
        background-color: #f7e8e3;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .noC-item-login {
    margin-top: 25px;
    width: 396px;
    height: 50px;
    background: #357aff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 10px;
    font-weight: 400;
    font-size: 22px;
    line-height: 21px;
    cursor: pointer;
    color: #ffffff;
  }
}
</style>
