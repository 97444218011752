<template>
  <div class="mt-button" :style="styleCss">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "mtButton",
  props: {
    width: {
      type: String,
      default: "243",
    },
    height: {
      type: String,
      default: "64",
    },
    radius: {
      type: String,
      default: "20",
    },
    text: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "22",
    },
  },
  computed: {
    styleCss() {
      return {
        "--width": this.width,
        "--height": this.height,
        "--border-radius": this.radius,
        "--fontSize": this.fontSize,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-button {
  width: calc(var(--width) * 1px);
  height: calc(var(--height) * 1px);
  border-radius: calc(var(--border-radius) * 1px);
  font-size: calc(var(--fontSize) * 1px);
  background: #357aff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
}
.mt-button:hover {
  background: #2e6de7;
}
.mt-button:active {
  background: #1f5acc;
}
</style>
