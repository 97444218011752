<template>
  <div class="content"></div>
</template>
<script>
export default {
  name: "homeShow",
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/home-image.png");
  background-size: 100% 100%;
}
</style>
