<template>
  <div class="header-product">
    <div
      class="text"
      v-for="(item, index) in productIndex"
      @click="linkTo(item.toRoute)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "header-product",
  data() {
    return {
      productIndex: [
        {
          name: "灵思",
          img: require("@/assets/product/img1.png"),
          hoverImg: require("@/assets/product/img1-hover.png"),
          toRoute: "product-linkMior",
        },
        {
          name: "灵点",
          img: require("@/assets/product/img2.png"),
          hoverImg: require("@/assets/product/img2-hover.png"),
          toRoute: "product-linkPoint",
        },
        {
          name: "灵秀",
          img: require("@/assets/product/img3.png"),
          hoverImg: require("@/assets/product/img3-hover.png"),
          toRoute: "product-linkShow",
        },
      ],
    };
  },
  methods: {
    linkTo(href) {
      this.$router.push({
        name: href,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-product {
  width: 124px;
  height: 208px;
  // padding-top: 56px;
  color: rgba(51, 52, 57, 0.7);
  font-weight: 400;
  font-size: 14px;
  .text {
    cursor: pointer;
    margin-top: 35px;
    text-align: center;
    padding: 6px 0;

    &:hover {
      background: rgba(53, 122, 255, 0.1);
      width: 100%;
      color: #357aff;
    }
  }
}
</style>
