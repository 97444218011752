<template>
  <div class="noC-max">
    <div class="noC-reigster-sys" v-if="isReg">
      <register-container>
        <div class="noC-reigster-wenzi" slot="header">
          <div class="noC-reg-icon">
            <el-checkbox v-model="checked"> </el-checkbox>
          </div>
          <div class="noC-icon-wenzi">
            注册即代表同意<span class="noC-active-wenzi">《用户服务协议》</span>
          </div>
        </div>
        <div class="noC-reg-bottom" slot="bottom">
          已有账号<span class="noC-active-wenzi" @click.stop="login">立即登录</span>
        </div>
      </register-container>
    </div>
    <div class="noC-login-sys" v-if="isLoginSys">
      <login-container>
        <div class="noC-login-wenzi">
          <div>
            没有账号?<span class="noC-active-wenzi" @click="reg">立即注册</span>
          </div>
          <div class="noC-foget" @click="forget">忘记密码</div>
        </div>
      </login-container>
    </div>
    <ForgetPassword v-if="isShowPassword"></ForgetPassword>
  </div>
</template>

<script>
import ForgetPassword from "../forget-password/index.vue";
export default {
  name: "loginRegister",
  data() {
    return {
      isExit:true,
      isLoginSys: true,
      checked: false,
      isReg: false,
      isShowPassword: false,
    };
  },
  methods: {
    forget() {
      this.isReg = false;
      this.isLoginSys = false;
      this.isShowPassword = true;
    },
    reg() {
      this.isLoginSys = false;
      this.isReg = true;
    },
    login() {
      this.isLoginSys = true;
      this.isReg = false;
    },
  },
  components: { ForgetPassword },
};
</script>

<style lang="scss" scoped>
.noC-max {
  pointer-events: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
}
.noC-login-sys {
  .noC-login {
    padding: 60px 102px;
  }
  .noC-login-wenzi {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
}
.noC-foget {
  cursor: pointer;
}
.noC-active-wenzi {
  color: #357AFF;
  cursor: pointer;
  margin-left: 3px;
}
.noC-reigster-wenzi {
  display: flex;
  font-size: 14px;

  .noC-icon-wenzi {
    margin-left: 4px;
  }
}
.noC-reg-bottom {
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}
</style>
